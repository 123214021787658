/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after, .mat-input-element:disabled {
  color: rgba(0,0,0,.7);
}

.small-pad-right {
  padding-right: 5px;
}

.marker {
  background-size: cover;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.multiple-marker {
  @extend .marker;
  background-image: url('./assets/mapbox/yellow10.png');
}

.orange-marker {
  @extend .marker;
  background-image: url('./assets/mapbox/orange10.png');
}

.green-marker {
  @extend .marker;
  background-image: url('./assets/mapbox/green10.png');
}

.red-marker {
  @extend .marker;
  background-image: url('./assets/mapbox/red10.png');
}

.blue-marker {
  @extend .marker;
  background-image: url('./assets/mapbox/blue10.png');
}

.larger-form-fields mat-form-field {
  width: 100%;
}

.secondary-toolbar {
  background-color: white;
}
